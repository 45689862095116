import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { getProduct, getProductUpdateLogs, getProductSettings } from '../../services/api/queryService';
import { useQuery } from '@apollo/react-hooks';

/** Components */
import '../product/Product.scss';
import DetailsHeader from '../../components/details-header/DetailsHeader';
import RequiredDataModal from './RequiredDataModal';


const ProductEditLogs = ({ getProductTitle }) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [productLogData, setProductLogs] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const [ openRequiredDataModal, setOpenRequiredDataModal ] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({ requiredData: '' });
  function handleShow(requiredData){
    setCurrentModalData({
      requiredData: requiredData,
    });
    setOpenRequiredDataModal(true);
  }
  const handleModalClose = () => setOpenRequiredDataModal(false);

  const slug = location.pathname.split('/')[2]

  const tabClickHandler = tabId => {
    switch(tabId){
      case 'listings':
        history.push(`/listings/${slug}/edit`);
        break;
      case 'unit-economics':
        history.push(`/unit-economics/${slug}`);
        break;
      case 'product':
        history.push(`/products/${slug}/edit`);
        break;
      case 'images':
          history.push(`/listings/${slug}/images`);
          break;
      default:
        history.push(`/products/${slug}/logs`);
    }
  };

  const { data: productData, refetch } = useQuery(getProduct, {
    variables: { slug: slug }
  });
  
  useEffect(() => { 
    if(productData && productData.product){
      let pckgName = ''
      if(+productData.product.product_type_id !== 8){
        pckgName = `| ${productData.product.packagingUnit?.name}`
      }

      getProductTitle(`${productData.product.product_name} ${pckgName}`)
    }
  }, [productData]);

  const {loading: logLoading, data: logData, refetch: refetchLogs } = useQuery(getProductUpdateLogs, {
    variables: { 
      slug
    }
  });

  useEffect(() => {
    if(logData && logData.productUpdateLogs){
      setProductLogs(logData.productUpdateLogs);
    }
  }, [logData]);


  return (
      <>
       <div className="row">
          <div className="col-12">
          <div className="page-title-box">
              <h4 className="page-title">
                Edit log
              </h4>

              <div className="row">
              <div className="col-12">
                  <div className="page-title-box">
                  
                  <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                          <Link to="/">PIM</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit log</li>
                  </ol>
                  </div>
              </div>
              </div>
          </div>
          </div>
      </div>
        {/* end page title */}

        { productData && productData.product && <DetailsHeader renderFor="products" data={ !productData ?  '' : productData.product} /> }
        { openRequiredDataModal && <RequiredDataModal requiredData={currentModalData.requiredData} setModalOpen={openRequiredDataModal} handleModalClose={handleModalClose} /> }      

        <div className="product-tabs product-tabs-custom col-10">
          <div
          className={`tab`}
          onClick={tabClickHandler.bind(null, 'product')}
          >
          Information
          </div>
      
          <div
          className={`tab `}
          onClick={tabClickHandler.bind(null, 'listings')}
          >
          Content
          </div>
          <div
          className={`tab`}
          onClick={null}
          >
          Update log
          </div>
          <div
          className={`tab`}
          onClick={tabClickHandler.bind(null, 'images')}
          >
          Images
          </div>
        
          <div
              className={`tab active`}
              onClick={tabClickHandler}
            >
              Edit log
            </div>
        <div className="tab-bar" />
      </div>

      {/* Check if we retrieved log data from the DB, then display that data */}
      {
        logLoading ? (
          <div className="spinner absolute">Spinner</div>
        ) : (
            <div className="sections-page-table" id="productLogs">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table id="basic-datatable" className="table table-hover dt-responsive nowrap">
                          <thead>
                              <tr>
                                <th>ID</th>
                                <th>Edit by</th>
                                <th>Edit field</th>
                                <th>Old value</th>
                                <th>New value</th>
                                <th>Last update</th>
                              </tr>
                          </thead>
                          <tbody>
                            { productLogData.map((log, i) => (
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{ log.user.first_name } {log.user.last_name}</td>
                                    <td>{ log.field }</td>
                                    <td>{ log.old_value }</td>
                                    <td>{ log.new_value }</td>
                                    <td>{ log.updated_at }</td>
                                </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default ProductEditLogs;