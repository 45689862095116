import React, { useCallback, useEffect, useMemo, useState } from 'react';

/** UI-components */
import Td from '../ui-components/td/Td';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { getManufacturer } from '../../services/api/queryService';
import { useLazyQuery } from '@apollo/react-hooks';

const TableBody = ({
  openNewTab,
  keys,
  content,
  moduleName,
  refetch,
  history,
  selectedItems,
  hasAction,
  onAction,
  notClickable,
  setSelectedItems,
  isListing,
  selectData,
  selectedProductTab,
  saveForm,
  loading,
  reset
}) => {
  const updated = useSelector(state => state.ui.draw);
  const dispatch = useDispatch();
  const isProduct = moduleName === 'products';
  const isManufacturer = moduleName === 'manufacturers';  
  let brands = []
  const [list, setList] = useState(content)
  const [oldList, setOldList] = useState([])

  

  useEffect(() => {
    const cnt = content.map(x => {
      x.edit = false
      return x
    })


    setOldList([...cnt])
    setList([...cnt])
  }, [content])


  if (updated) {
    refetch();

    dispatch({
      type: 'DRAW',
      payload: false
    });
  }


  const checkboxClickHandler = (item, event) => {
    let updatedSelectedItems = [...selectedItems];
    if (event.target.checked) {
      updatedSelectedItems.push(+item.product_parent_id);
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(i => i !== +item.product_parent_id);
    }

    updatedSelectedItems.sort((a, b) => b - a);
    setSelectedItems(updatedSelectedItems);
  };

  const clickHandler = id => {
    if (openNewTab) {
      if (moduleName !== 'importJobs') {
        const win = window.open(`/${moduleName}/${id}/edit`, '_blank');
        win.focus();
      } else {
        const win = window.open(`/products/import/${id}`, '_blank');
        win.focus();
      }
    } else {
      if (moduleName !== 'importJobs') {
        history.push(`/${moduleName}/${id}/edit`);
      } else {
        history.push(`/products/import/${id}`);
      }
    }
  };

  const saveChange = (obj) => {
    const {index, value, field} = obj
    const formList = [...list]
    let reset = false
    const pl = JSON.parse(localStorage.getItem('productsList'))

    formList.map((x, i) => {
      if(i == index){
        if(field == 'edit'){
          formList[index]['edit'] = value
          
          reset = true
        }else{
          formList[index][field] = value
        }
      }else{
        formList[i]['edit'] = undefined
      }
    })

    if(field == 'manufacturer') formList[index]['brand'] = null

    if(reset){
      pl.map((x, i) => {
        Object.keys(x).map(w => {
          formList[i][w] = x[w]
        })
      })
    }

    setList([...formList])
  }

  const submitForm = (i, field) => {
    const payload = {
      data: JSON.stringify(list[i]),
      field,
      type: selectedProductTab
    }

    console.log('submit')
    setOldList([...list])
    saveForm(payload)
  }


  if(loading){
    return <></>
  }

  return (
    <tbody>
      {list.map((item, index) => {
        return (
          <tr key={index}>
            {(isProduct || isListing) && (
              <td className="text-center" id={item.product_parent_id}>
                <input
                  type="checkbox"
                  checked={selectedItems.some(i => +i === +item.product_parent_id)}
                  onChange={checkboxClickHandler.bind(null, item)}
                />
              </td>
            )}
            {keys.map((fieldKey, i) => {
              if(fieldKey == 'brand' && selectData){

                brands = selectData['manufacturers'].find(x => x.name == item['manufacturer'])?.brands??[]
              }
              if(item[fieldKey] === null) item[fieldKey] = ''
              if(fieldKey === 'product_details' && item['product_type_id'] === 8) item[fieldKey] = 'Parent product'
              if (fieldKey !== 'embeddCopyedLocation' && fieldKey !== 'slug' && fieldKey !== 'showFilter' && fieldKey !== 'alt_name' 
              && fieldKey !== 'showFlag' && fieldKey !== 'user_tab_position' && fieldKey !== 'product_type_id' && fieldKey !== 'product_child_id' && fieldKey !== 'roleId' && fieldKey !== 'explanation_url' && fieldKey !== 'showExplanation') {
                return (
                  <Td
                    key={i}
                    index={index}
                    field={fieldKey}
                    oldValue={fieldKey === 'product_parent_id' || ( isManufacturer && fieldKey === 'id') ? item['slug'] : oldList[index][fieldKey]}
                    value={fieldKey === 'product_parent_id' || ( isManufacturer && fieldKey === 'id') ? item['slug'] : item[fieldKey]}
                    item={item}
                    notClickable={fieldKey != 'product_parent_id' && fieldKey != 'slug' && fieldKey != 'name'}
                    moduleName={moduleName}
                    isListing={isListing}
                    selectData={selectData}
                    brands={brands}
                    saveChange={saveChange}
                    editItem={item.edit}
                    submitForm={submitForm}
                  />
                );
              }
            })}
            {hasAction && (
              <td className="text-center">
                <i onClick={() => onAction(item)} className="fas fa-edit" style={{cursor: 'pointer' }}></i>
              </td>
            )}

            {/* { item.edit && (
              <div className="d-flex">
                <button className='btn' onClick={() => submitForm(index)}>
                  ✅
                </button>
                <button className='btn' onClick={() => saveChange({index, value: false, field: "edit"})}>
                  ❌
                </button>
              </div>
            ) } */}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
